import React, { useEffect, useRef } from 'react';

export default function StationDetailFragment({ station, environment }) {
    const artworkImgRef = useRef();
    const widgetImgRef = useRef();

    useEffect(() => {
        if (station.fields.artwork) {
            const imgData = "data:image/png;base64," + station.fields.artwork.value;
            artworkImgRef.current.src = imgData;
        }

        if (station.fields.widget) {
            const imgData = "data:image/png;base64," + station.fields.widget.value;
            widgetImgRef.current.src = imgData;
        }
    }, [station])

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-900">Title</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.title && station.fields.title.value}
                        {!station.fields.title && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-900">Subtitle</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.subtitle && station.fields.subtitle.value}
                        {!station.fields.subtitle && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-900">Description</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.descr && station.fields.descr.value}
                        {!station.fields.descr && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-900">Address</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.address && station.fields.address.value}
                        {!station.fields.address && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">ISO 3166</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.iso3166.value.map((iso, index) => (
                            <span key={index}>{(index ? ', ' : '') + iso}</span>
                        ))}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">ISO 639</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.iso639 && station.fields.iso639.value}
                        {!station.fields.iso639 && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Homepage</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.homepage && station.fields.homepage.value}
                        {!station.fields.homepage && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Twitter</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.twitter && station.fields.twitter.value}
                        {!station.fields.twitter && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Instagram</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.instagram && station.fields.instagram.value}
                        {!station.fields.instagram && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Youtube</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.youtube && station.fields.youtube.value}
                        {!station.fields.youtube && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Country code</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.countryCode && station.fields.countryCode.value}
                        {!station.fields.countryCode && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Country sub code</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.countrySubCode && station.fields.countrySubCode.value}
                        {!station.fields.countrySubCode && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">State</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.state && station.fields.state.value}
                        {!station.fields.state && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-900">Tags</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.tags && station.fields.tags.value}
                        {!station.fields.tags && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Codec</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.codec && station.fields.codec.value}
                        {!station.fields.codec && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Bitrate</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.bitrate && station.fields.bitrate.value}
                        {!station.fields.bitrate && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Sampling frequency</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.samplingFrequency && station.fields.samplingFrequency.value}
                        {!station.fields.samplingFrequency && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Supports HLS</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.supportsHls && station.fields.supportsHls.value === 0 && "No"}
                        {station.fields.supportsHls && station.fields.supportsHls.value === 1 && "Yes"}
                        {!station.fields.supportsHls && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Has extended info</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.hasExtendedInfo && station.fields.hasExtendedInfo.value === 0 && "No"}
                        {station.fields.hasExtendedInfo && station.fields.hasExtendedInfo.value === 1 && "Yes"}
                        {!station.fields.hasExtendedInfo && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">GUID</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.guid && station.fields.guid.value}
                        {!station.fields.guid && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Change GUID</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.changeGuid && station.fields.changeGuid.value}
                        {!station.fields.changeGuid && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">API provider</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.apiProvider && station.fields.apiProvider.value}
                        {!station.fields.apiProvider && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Section</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.section && station.fields.section.value}
                        {!station.fields.section && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Language codes (app display)</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.languageCodes && station.fields.languageCodes.value}
                        {!station.fields.languageCodes && "—"}
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Station artwork</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        <img ref={artworkImgRef} className='w-32 h-32' alt="" />
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Widget artwork</dt>
                    <dd className="mt-1 text-sm text-gray-900 bg-gray-500 w-16 h-16">
                        <img ref={widgetImgRef} className='w-16 h-16' alt="" />
                    </dd>
                </div>
                <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Active</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                        {station.fields.active && station.fields.active.value === 1 && `Yes, ${environment.title}`}
                        {station.fields.active && station.fields.active.value === 0 && `No, ${environment.title}`}
                        {!station.fields.active && "—"}
                    </dd>
                </div>
            </dl>
        </div>
    )
}