import { useState, useEffect } from 'react';
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";

// https://blog.bitsrc.io/fetching-data-in-react-using-hooks-c6fdd71cb24a

export function useCloudKitSection(sectionId, environment) {
    // HOOKS
    const auth = useAuth();

    // STATE
    const [section, setSection] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://transmissionfm.app/api/featured/fetch?' + new URLSearchParams({ q: sectionId, environment: environment.path }), {
                    method: 'GET',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    }
                })
                    .catch((err) => {
                        setLoading(false);
                        console.log("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            console.log("Error " + data.error);
                            setLoading(false);
                        } else {
                            if (data.record) {
                                // console.log("data.message.records = " + JSON.stringify(data.message.records))
                                setSection(data.record);
                                setLoading(false);
                            } else {
                                //console.log("data.message.records = empty")
                                setLoading(false);
                            }
                        }
                    });
            })
            .catch((errorJwt) => {
                console.log("Error - invalid auth token");
                setLoading(false);
            });
    }, [sectionId, auth.currentUser, environment])

    return loading === false ? section : undefined;
}

export function useCloudKitStation(stationId, environment) {
    // HOOKS
    const auth = useAuth();

    // STATE
    const [station, setStation] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://transmissionfm.app/api/featured/fetch?' + new URLSearchParams({ q: stationId, environment: environment.path }), {
                    method: 'GET',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    }
                })
                    .catch((err) => {
                        setLoading(false);
                        console.log("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            console.log("Error " + data.error);
                            setLoading(false);
                        } else {
                            if (data.record) {
                                // console.log("data.message.records = " + JSON.stringify(data.message.records))
                                setStation(data.record);
                                setLoading(false);
                            } else {
                                //console.log("data.message.records = empty")
                                setLoading(false);
                            }
                        }
                    });
            })
            .catch((errorJwt) => {
                console.log("Error - invalid auth token");
                setLoading(false);
            });
    }, [stationId, auth.currentUser, environment])

    return loading === false ? station : undefined;
}

export function useCloudKitStations(region, section, environment) {
    // HOOKS
    const auth = useAuth();

    // STATE
    const [stations, setStations] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://transmissionfm.app/api/featured/stations?' + new URLSearchParams({ iso3166: region, section: section, environment: environment.path }), {
                    method: 'GET',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    }
                })
                    .catch((err) => {
                        setLoading(false);
                        window.alert("Error " + err);
                        return undefined;
                    })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        if (data.error) {
                            window.alert("Error " + data.error);
                            setLoading(false);
                        } else {
                            if (data.message.records) {
                                // window.alert("data.message.records = " + JSON.stringify(data.message.records));
                                setStations(data.message.records);
                                setLoading(false);
                            } else {
                                window.alert("data.message.records = empty");
                                setLoading(false);
                            }
                        }
                    });
            })
            .catch((errorJwt) => {
                console.log("Error - invalid auth token");
                setLoading(false);
            });
    }, [region, section, auth.currentUser, environment])

    return loading === false ? stations : undefined;
}

export function useCloudKitSections(environment, region) {
    // HOOKS
    const auth = useAuth();

    // STATE
    const [sections, setSections] = useState(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true);

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                fetch('https://transmissionfm.app/api/featured/sections?' + new URLSearchParams({ iso3166: region, environment: environment.path }), {
                    method: 'GET',
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                        "Authorization": "Bearer " + jwtToken,
                    }
                })
                    .catch((err) => {
                        setLoading(false);
                        console.log("Error " + err);
                    })
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            console.log("Error " + data.error);
                            setLoading(false);
                        } else {
                            console.log("data.message = " + JSON.stringify(data.message))
                            if (data.message.records) {
                                setSections(data.message.records);
                                setLoading(false);
                            } else {
                                setLoading(false);
                            }
                        }
                    });
            })
            .catch((errorJwt) => {
                console.log("Error - invalid auth token");
                setLoading(false);
            });
    }, [region, auth.currentUser, environment])

    return loading === false ? sections : undefined;
}

export function useSupportedEnvironments() {
    const environments = [
        { title: "Development", path: "development" }, 
        { title: "Production", path: "production" }
    ];

    return environments;
}

export function useSupportedRegions() {
    const regions = [
        { flag: "us.svg", name: "United States of America", code: "US" },
        { flag: "za.svg", name: "South Africa", code: "ZA" },
        { flag: "gb.svg", name: "United Kingdom", code: "GB" },
        { flag: "ie.svg", name: "Ireland", code: "IE" },
        { flag: "fr.svg", name: "France", code: "FR" },
        { flag: "de.svg", name: "Germany", code: "DE" },
        { flag: "jp.svg", name: "Japan", code: "JP" },
        { flag: "ar.svg", name: "Argentina", code: "AR" },
        { flag: "es.svg", name: "Spain", code: "ES" },
        { flag: "mx.svg", name: "Mexico", code: "MX" },
        { flag: "nl.svg", name: "Netherlands", code: "NL" },
        { flag: "nz.svg", name: "New Zealand", code: "NZ" },
        { flag: "at.svg", name: "Austria", code: "AT" },
        { flag: "ch.svg", name: "Switzerland", code: "CH" },
        { flag: "in.svg", name: "India", code: "IN" },
        { flag: "ca.svg", name: "Canada", code: "CA" },
    ];

    return regions;
}
