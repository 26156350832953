import { Fragment, useState, useCallback } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/solid';
import { useSupportedEnvironments } from './Hooks';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SystemChooser({ handler }) {    
    const environments = useSupportedEnvironments();
    const [selectedEnvironment, setSelectedEnvironment] = useState(environments[0]);

    const setActiveCallback = useCallback((selectedSystem) => {
        setSelectedEnvironment(selectedSystem);
        handler(selectedSystem);
    }, [handler]);

    return (
        <Menu as="div" className="px-3 mt-6 relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none">
                            <span className="flex w-full justify-between items-center">
                                <span className="flex min-w-0 items-center justify-between space-x-3">
                                    <span className="flex-1 flex flex-col min-w-0">
                                        <span className="text-gray-500 text-sm truncate">Environment: {selectedEnvironment.title}</span>
                                    </span>
                                </span>
                                <SelectorIcon className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            </span>
                        </Menu.Button>
                    </div>

                    <Transition show={open} as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                        <Menu.Items static className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                            <div className="py-1">
                                {environments.map((system) => (
                                    <Menu.Item key={system.title}>
                                        <button onClick={() => setActiveCallback(system)} className={classNames((selectedEnvironment.path === system.path) ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'text-left w-full block px-4 py-2 text-sm')}>
                                            {system.title}
                                        </button>
                                    </Menu.Item>
                                ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    )
}