import axios from 'axios';
import * as Yup from "yup"
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import ConfirmDialog from './ConfirmDialog';
import { Formik, ErrorMessage } from 'formik';
import { useNavigate } from "react-router-dom";
import React, { useState, useRef } from 'react';

export default function EditStationFragment({ regionId, sectionId, station, environment }) {
    // STATE
    const [isSubmitting, setisSubmitting] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    // HOOKS
    const navigate = useNavigate();
    const formikRef = useRef(null);
    const auth = useAuth();

    // HELPERS

    const implodeIso3166 = (arr) => {
        return arr.join(',');
    }

    const explodeIso3166 = (str) => {
        return str.split(',');
    }

    // FORM

    const initialValues = {
        title: station.fields.title.value,
        subtitle: (station.fields.subtitle !== undefined) ? station.fields.subtitle.value : '',
        descr: (station.fields.descr !== undefined) ? station.fields.descr.value : '',
        address: station.fields.address.value,
        iso3166: (station.fields.iso3166 !== undefined) ? implodeIso3166(station.fields.iso3166.value) : '',
        tags: (station.fields.tags !== undefined && station.fields.tags.value !== "undefined") ? station.fields.tags.value : '',
        iso639: (station.fields.iso639 !== undefined) ? station.fields.iso639.value.toUpperCase() : '',
        homepage: (station.fields.homepage !== undefined) ? station.fields.homepage.value : '',
        twitter: (station.fields.twitter !== undefined) ? station.fields.twitter.value : '',
        instagram: (station.fields.instagram !== undefined) ? station.fields.instagram.value : '',
        youtube: (station.fields.youtube !== undefined) ? station.fields.youtube.value : '',
        countryCode: (station.fields.countryCode !== undefined) ? station.fields.countryCode.value : '',
        countrySubCode: (station.fields.countrySubCode !== undefined) ? station.fields.countrySubCode.value : '',
        state: (station.fields.state !== undefined) ? station.fields.state.value : '',
        codec: (station.fields.codec !== undefined) ? station.fields.codec.value : '',
        bitrate: (station.fields.bitrate !== undefined) ? station.fields.bitrate.value : '',
        supportsHls: (station.fields.supportsHls !== undefined) ? station.fields.supportsHls.value : '',
        hasExtendedInfo: (station.fields.hasExtendedInfo !== undefined) ? station.fields.hasExtendedInfo.value : '',
        guid: (station.fields.guid !== undefined) ? station.fields.guid.value : '',
        changeGuid: (station.fields.changeGuid !== undefined) ? station.fields.changeGuid.value : '',
        apiProvider: (station.fields.apiProvider !== undefined) ? station.fields.apiProvider.value : '',
        section: (station.fields.section !== undefined) ? station.fields.section.value : '',
        languageCodes: (station.fields.languageCodes !== undefined) ? station.fields.languageCodes.value : '',
        active: (station.fields.active !== undefined) ? station.fields.active.value : '',
    };
    const validationSchema = Yup.object({
        title: Yup.string().required(),
        subtitle: Yup.string().optional(),
        descr: Yup.string().optional(),
        address: Yup.string().url().required(),
        iso3166: Yup.string().required(),
        tags: Yup.string().optional(),
        iso639: Yup.string().test('len', 'Must be 2 characters', val => (val !== undefined && val.trim().length === 2)),
        homepage: Yup.string().url().optional(),
        twitter: Yup.string().optional(),
        instagram: Yup.string().optional(),
        youtube: Yup.string().optional(),
        countryCode: Yup.string().test('len', 'Must be 2 characters', val => (val === undefined || val.trim() === "" || val.trim().length === 2)),
        countrySubCode: Yup.string().optional(),
        state: Yup.string().optional(),
        codec: Yup.string().optional(),
        bitrate: Yup.number().optional(),
        samplingFrequency: Yup.number().optional(),
        supportsHls: Yup.number().min(0).max(1),
        hasExtendedInfo: Yup.number().min(0).max(1),
        guid: Yup.string().required(),
        changeGuid: Yup.string().required(),
        apiProvider: Yup.number().required().min(0).max(1),
        languageCodes: Yup.string().optional(),
        section: Yup.string().required(),
        active: Yup.number().min(0).max(1),
    });

    const getChangedValues = (values, initialValues) => {
        return Object
          .entries(values)
          .reduce((acc, [key, value]) => {
            const hasChanged = initialValues[key] !== value
      
            if (hasChanged) {
              acc[key] = value
            }
      
            return acc
          }, {})
      }

    async function handleSubmit(form) {
        setisSubmitting(true);

        // start with required fields: title, section, address, iso3166, iso639 and default active, apiProvider
        const updatePayload = {
            "environment": environment.path,
            "recordName": station.recordName,
            "fields": {  }
        };

        // detect which fields changed
        const changedFields = getChangedValues(formikRef.current.values, formikRef.current.initialValues);

        // merge into the form, with some special cases
        Object.entries(changedFields).forEach(([key, value]) => {
            if (key === "bitrate" || key === "samplingFrequency" || key === "supportsHls" || key === "hasExtendedInfo" || key === "apiProvider" || key === "active") {
                updatePayload.fields[key] = { "value": Number(value) }
            } else if (key === "iso3166") {
                updatePayload.fields[key] = { "value": explodeIso3166(value) }
            } else {
                updatePayload.fields[key] = { "value": value }
            }
        });

        // Debug:
        // window.alert(JSON.stringify(updatePayload));  return;

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                axios.post("https://transmissionfm.app/api/featured/updatestation", updatePayload)
                    .then(function (response) {
                        if (response.data !== undefined && response.data.error !== undefined && response.data.error === false) {
                            setConfirmDialogOpen(true);
                            setisSubmitting(false);
                        } else {
                            window.alert("Invalid data received:" + JSON.stringify(response.data.error));
                            setisSubmitting(false);
                        }
                    })
                    .catch(function (error) {
                        window.alert(error);
                        setisSubmitting(false);
                    });
            })
            .catch((errorJwt) => {
                window.alert("Error - invalid auth token");
                setisSubmitting(false);
            });
    }

    // LIFTING STATE

    const confirmDialogHandler = (isOpen) => {
        setConfirmDialogOpen(isOpen);
        navigate(`/app/featured/${regionId}/${sectionId}`);
    }

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

            <ConfirmDialog defaultOpen={confirmDialogOpen} handler={confirmDialogHandler} message="The station was updated successfully." />

            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, errors }) => (
                    <form onSubmit={handleSubmit} className="">

                        {/* Debug */}
                        {Object.entries(errors).map(([key, value]) => {
                            return <p className='mb-2 text-red-900'><span className='font-bold'>{key}:</span> {value}</p>;
                        })}

                        <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Title &nbsp;
                                        <ErrorMessage name="title" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="title" id="title" defaultValue={initialValues.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Subtitle &nbsp;
                                        <ErrorMessage name="subtitle" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="subtitle" id="subtitle" defaultValue={initialValues.subtitle} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Description &nbsp;
                                        <ErrorMessage name="descr" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="descr" id="descr" defaultValue={initialValues.descr} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Address &nbsp;
                                        <ErrorMessage name="address" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="address" id="address" defaultValue={initialValues.address} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>                                
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        ISO 3166 country &nbsp;
                                        <ErrorMessage name="iso3166" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="iso3166" id="iso3166" defaultValue={initialValues.iso3166} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        ISO 639 language &nbsp;
                                        <ErrorMessage name="iso639" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="iso639" id="iso639" defaultValue={initialValues.iso639} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Homepage &nbsp;
                                        <ErrorMessage name="homepage" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="homepage" id="homepage" defaultValue={initialValues.homepage} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Twitter &nbsp;
                                        <ErrorMessage name="twitter" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="twitter" id="twitter" defaultValue={initialValues.twitter} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Instagram &nbsp;
                                        <ErrorMessage name="instagram" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="instagram" id="instagram" defaultValue={initialValues.instagram} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Youtube &nbsp;
                                        <ErrorMessage name="youtube" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="youtube" id="youtube" defaultValue={initialValues.youtube} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Country code &nbsp;
                                        <ErrorMessage name="countryCode" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="countryCode" id="countryCode" defaultValue={initialValues.countryCode} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Country sub code &nbsp;
                                        <ErrorMessage name="countrySubCode" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="countrySubCode" id="countrySubCode" defaultValue={initialValues.countrySubCode} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        State &nbsp;
                                        <ErrorMessage name="state" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="state" id="state" defaultValue={initialValues.state} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Tags &nbsp;
                                        <ErrorMessage name="tags" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="tags" id="tags" defaultValue={initialValues.tags} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Codec &nbsp;
                                        <ErrorMessage name="codec" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="codec" id="codec" defaultValue={initialValues.codec} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Bitrate &nbsp;
                                        <ErrorMessage name="bitrate" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="bitrate" id="bitrate" defaultValue={initialValues.bitrate} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Sampling frequency &nbsp;
                                        <ErrorMessage name="samplingFrequency" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="samplingFrequency" id="samplingFrequency" defaultValue={initialValues.samplingFrequency} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Supports HLS &nbsp;
                                        <ErrorMessage name="supportsHls" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="supportsHls" id="supportsHls" defaultValue={initialValues.supportsHls} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Has extended info &nbsp;
                                        <ErrorMessage name="hasExtendedInfo" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="hasExtendedInfo" id="hasExtendedInfo" defaultValue={initialValues.hasExtendedInfo} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Station GUID &nbsp;
                                        <ErrorMessage name="guid" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="guid" id="guid" defaultValue={initialValues.guid} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Change GUID &nbsp;
                                        <ErrorMessage name="changeGuid" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="changeGuid" id="changeGuid" defaultValue={initialValues.changeGuid} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        API provider &nbsp;
                                        <ErrorMessage name="apiProvider" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                    <input type="text" name="apiProvider" id="apiProvider" defaultValue={initialValues.apiProvider} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Section &nbsp;
                                        <ErrorMessage name="section" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="section" id="section" defaultValue={initialValues.section} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        languageCodes (app display) &nbsp;
                                        <ErrorMessage name="languageCodes" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="languageCodes" id="languageCodes" defaultValue={initialValues.languageCodes} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Active &nbsp;
                                        <ErrorMessage name="active" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="active" id="active" defaultValue={initialValues.active} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Environment
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {environment.title}
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        {/* Button actions */}
                        <div className="mt-5 flex justify-end"> {/* disabled={formLoading || !isValid}  */}
                            <button type="submit" disabled={isSubmitting || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-blue-500 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900">
                                Save
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div >
    )
}
