import axios from 'axios';
import * as Yup from "yup"
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import React, { Fragment, useState, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon } from '@heroicons/react/outline'

export default function CreateSectionOverlay({ closeHandler, defaultOpen, defaultRegion, environment }) {
    // HOOKS
    const auth = useAuth();

    // STATE
    const [open, setOpen] = useState(defaultOpen)
    const nameRef = useRef();
    const iso3166Ref = useRef();
    const iso639Ref = useRef();
    const positionRef = useRef();
    const activeRef = useRef();

    React.useEffect(() => {
        setOpen(defaultOpen);
    }, [defaultOpen])

    // FUNCTIONS

    function closeDialog() {
        setOpen(false);
        closeHandler(false);
    }

    // FORM

    const validationSchema = Yup.object({
        name: Yup.string("Choose name").required("Name is required"),
        iso3166: Yup.string().test('iso3166', 'iso3166 must be exactly 2 characters', val => val.length === 2),
        iso639: Yup.string("Choose iso639").test('iso639', 'iso639 must be exactly 2 characters', val => val.length === 2),
        position: Yup.number("Choose position").required("Required").min(0).max(99),
        active: Yup.number("Choose position").required("Required").min(0).max(99),
    });

    async function handleSubmit() {
        try {
            await validationSchema.validate({ "name": nameRef.current.value, "iso3166": iso3166Ref.current.value, "iso639": iso639Ref.current.value, "position": positionRef.current.value, "active": activeRef.current.value });
        } catch (err) {
            window.alert("Error: " + err);
            return;
        }

        const postPayload = {
            "environment": environment.path,
            "fields": {
                "iso3166": {
                    "value": [
                        iso3166Ref.current.value
                    ]
                },
                "iso639": {
                    "value": iso639Ref.current.value
                },
                "active": {
                    "value": parseInt(activeRef.current.value)
                },
                "name": {
                    "value": nameRef.current.value
                },
                "position": {
                    "value": parseInt(positionRef.current.value)
                }
            }
        };

        // window.alert(JSON.stringify(postPayload));
        // return;

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                axios.post("https://transmissionfm.app/api/featured/createsection", postPayload)
                    .then(function (response) {
                        if (response && response.data && response.data.error !== undefined && response.data.error === false) {
                            closeDialog();
                        } else {
                            window.alert("Invalid data received: " + JSON.stringify(response));
                        }
                    })
                    .catch(function (error) {
                        window.alert(error);
                    });
            })
            .catch((errorJwt) => {
                window.alert("Error - invalid auth token");
            });
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeDialog}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >


                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">



                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={() => closeDialog()} >
                                    <span className="sr-only">Close</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-isober-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationIcon className="h-6 w-6 text-isober-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        Create Section ({environment.path})
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">The details for the selected station are:</p>
                                    </div>

                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="name" id="name" ref={nameRef} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">ISO 3166</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="iso3166" id="iso3166" defaultValue={defaultRegion} ref={iso3166Ref} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">ISO 639</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="iso639" id="iso639" ref={iso639Ref} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Position</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="position" id="position" ref={positionRef} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                                            <dt className="text-sm font-medium text-gray-500">Active</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <input type="text" name="enabledx" id="enabledx" defaultValue="1" ref={activeRef} autoComplete="off" className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-isober-500 focus:border-isober-500 sm:text-sm" />
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button type="button" disabled={false} className="disabled:opacity-50 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => handleSubmit()}>
                                    Create
                                </button>
                                <button type="button" className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => closeDialog()} >
                                    Cancel
                                </button>
                            </div>

                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}