import { ChevronRightIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import CreateSectionOverlay from './CreateSectionOverlay';
import { useSupportedRegions, useCloudKitSections } from './Hooks';
import { HomeIcon } from '@heroicons/react/solid';
import { getIdToken } from "firebase/auth";
import { useAuth } from 'reactfire';
import axios from 'axios';

export default function RegionDetail({ environment }) {
    // HOOKS
    const { regionId } = useParams();
    const regions = useSupportedRegions();
    const sections = useCloudKitSections(environment, regionId);
    const auth = useAuth();

    // STATE
    const [createSectionVisible, setCreateSectionVisible] = useState(false);
    const [isSubmitting, setisSubmitting] = useState(false);

    const showCreateSection = () => {
        setCreateSectionVisible(true);
    }

    const hideCreateSection = () => {
        setCreateSectionVisible(false);
    }

    const syncToDevelopment = () => {
        if (window.confirm("Are you sure?")) {
            setisSubmitting(true);

            getIdToken(auth.currentUser, false)
                .then(jwtToken => {
                    axios.post("https://transmissionfm.app/api/featured/sync", { "iso3166": regionId })
                        .then(function (response) {
                            setisSubmitting(false);
                            window.alert(response.data.message);
                        })
                        .catch(function (error) {
                            setisSubmitting(false);
                            window.alert(error);
                        });
                })
                .catch((errorJwt) => {
                    setisSubmitting(false);
                    window.alert("Error - invalid auth token");
                });
        }
    }

    const region = regions.find(item => item.code === regionId);

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-2">

                        {/* Create section overlay */}
                        <CreateSectionOverlay closeHandler={hideCreateSection} defaultOpen={createSectionVisible} defaultRegion={regionId} environment={environment} />

                        {/* Breadcrumbs */}
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
                                <li className="flex">
                                    <div className="flex items-center">
                                        <Link to="/app/featured" className="text-gray-600 hover:text-black">
                                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                        </Link>
                                    </div>
                                </li>
                                <li key="00" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <span className="ml-4 text-sm font-medium text-black">
                                            {region && region.name && region.name}
                                        </span>
                                    </div>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                        {environment.path === 'production' && <button type="button" onClick={() => syncToDevelopment()} disabled={isSubmitting} className="disabled:opacity-50 mr-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sync to dev</button>}
                        <button type="button" onClick={() => { showCreateSection() }} className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create section</button>
                    </div>
                </div>
            </div>

            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                {!sections && <p>Loading sections</p>}

                {sections && sections.length === 0 && <p>No sections found.</p>}

                {sections && sections.length > 0 && <ul className="divide-y divide-gray-200">
                    <li key="-1">
                        <div className="flex items-center px-4 py-4 sm:px-6">
                            <div className="min-w-0 flex-1 flex items-center">
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                                    <div>
                                        <p className="text-sm font-bold text-black-600 truncate">Position</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-bold text-black-600 truncate">Name</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-bold text-black-600 truncate">ISO3166</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ChevronRightIcon className="h-5 w-5 text-gray-400 invisible" /> {/* invisble keeps consistent spacing with list below */}
                            </div>
                        </div>
                    </li>
                    {sections.map((item, itemIndex) => (
                        <li key={itemIndex}>
                            <Link to={`/app/featured/${regionId}/${item.recordName}`} className="block hover:bg-gray-50">
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center">
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                                            <div>
                                                <p className="text-sm font-normal text-black-600 truncate">{item.fields.position.value}</p>
                                            </div>
                                            <div>
                                                <p className="text-sm font-normal text-black-600 truncate">{item.fields.name.value}</p>
                                            </div>
                                            <div>
                                                <p className="text-sm font-normal text-black-600 truncate">
                                                    {item.fields.iso3166.value.map((iso, index) => (
                                                        <span key={index}>{(index ? ', ' : '') + iso}</span>
                                                    ))}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>}

            </div>
        </div>
    )
}