export default function LandingPage() {
    return (
        <div className="min-h-screen bg-white">
            <div className="flex">
                <div className="ml-20 pt-20">
                    <p className="font-sans text-lg">Web site coming soon.</p>
                </div>
            </div>
        </div>
    );
}