import axios from 'axios';
import React, { useState, useRef } from 'react';
import { PlusCircleIcon } from '@heroicons/react/solid';
import StationDetailsOverlay from './StationDetailsOverlay';
import { Link, useParams, useNavigate } from "react-router-dom";
import { HomeIcon } from '@heroicons/react/solid'
import { useSupportedRegions, useCloudKitSection } from './Hooks';
import CreateStationFragment from './CreateStationFragment';

export default function CreateStation({ environment }) {
    // STATE
    const [stations, setStations] = useState([]);
    const [stationDetailsOverlayOpen, setStationDetailsOverlayOpen] = useState(false);
    const [stationEditorOpen, setStationEditorOpen] = useState(false);
    const [selectedStation, setSelectedStation] = useState(undefined);

    // HOOKS
    const { regionId } = useParams();
    const { sectionId } = useParams();
    const inputBoxRef = useRef();
    const navigate = useNavigate();
    const regions = useSupportedRegions();
    const section = useCloudKitSection(sectionId, environment);
    const region = regions.find(item => item.code === regionId);

    const stationSearch = (q) => {
        const term = inputBoxRef.current.value;
        const termEncoded = encodeURIComponent(term);
        if (!term.trim()) {
            alert("Please enter a search term");
            return;
        }

        axios.get("https://de1.api.radio-browser.info/json/stations/byname/" + termEncoded) // , { params: { answer: 42 } }
            .then(function (response) {
                if (response && response.data) {
                    setStations(response.data);
                } else {
                    window.alert("Invalid data received");
                }
            })
            .catch(function (error) {
                window.alert(error);
            });
    }

    const stationInfoPopup = (station) => {
        setStationDetailsOverlayOpen(true);
        setSelectedStation(station);
    }

    const createStation = (station) => {
        setSelectedStation(station);
        setStationEditorOpen(true);
    }

    const stationDetailsHandler = (newVal) => {
        setStationDetailsOverlayOpen(newVal);
        if (!newVal) {
            setSelectedStation(undefined);
        }
    }

    const cancel = () => {
        navigate(`/app/featured/${regionId}/${sectionId}`);
    }

    const back = () => {
        setSelectedStation(undefined);
        setStationEditorOpen(false);
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-2">

                        {/* Breadcrumbs */}
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
                                <li className="flex">
                                    <div className="flex items-center">
                                        <Link to="/app/featured" className="text-gray-600 hover:text-black">
                                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                        </Link>
                                    </div>
                                </li>
                                <li key="00" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <Link to={`/app/featured/${region.code}`} className="ml-4 text-sm font-medium text-gray-600 hover:text-black">
                                            {region.name}
                                        </Link>
                                    </div>
                                </li>
                                <li key="01" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <Link to={`/app/featured/${regionId}/${sectionId}`} className="ml-4 text-sm font-medium text-gray-600 hover:text-black">
                                            {section && section.fields && section.fields.name.value}
                                            {(!section || !section.fields) && "Section"}
                                        </Link>
                                    </div>
                                </li>
                                <li key="02" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <span className="ml-4 text-sm font-medium text-black">Create station</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                        <button type="button" onClick={() => { cancel() }} className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
                        {stationEditorOpen && <button type="button" onClick={() => { back() }} className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Back</button>}
                    </div>
                </div>
            </div>

            {stationEditorOpen && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <CreateStationFragment station={selectedStation} region={region} section={section} environment={environment} />
            </div>}

            {!stationEditorOpen && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">Station name:</label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input type="text" name="q" id="q" ref={inputBoxRef} className="block w-full rounded-none rounded-l-md border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="France Info" />
                        </div>
                        <button type="button" onClick={() => stationSearch()} className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                            <span>Search</span>
                        </button>
                    </div>

                    {selectedStation && <StationDetailsOverlay handler={stationDetailsHandler} station={selectedStation} defaultOpen={stationDetailsOverlayOpen} />}

                    {/* Search results */}
                    {stations && stations.length > 0 && <div>
                        <h2 className="mt-6 text-sm font-medium text-gray-700">Search results:</h2>
                        <ul className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
                            {stations.map((station) => (
                                <li key={station.stationuuid} className="col-span-1 flex rounded-md shadow-sm">
                                    <div className="rounded-l-md flex flex-1 items-center justify-between truncate rounded-r-md border border-gray-200 bg-white">
                                        <div className="flex-1 truncate px-4 py-2 text-sm">
                                            <button onClick={() => stationInfoPopup(station)} className="my-1 font-medium text-gray-900 hover:text-gray-600">
                                                {station.name}
                                            </button>
                                            <p className="text-gray-500">
                                                {station.countrycode && <img src={`/flags/${station.countrycode.toLowerCase()}.svg`} className="inline-flex h-4 mr-2" alt="" />}
                                                <span className="mr-1 inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">{station.codec}</span>
                                                {station.bitrate !== 0 && <span className="mx-1 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">{station.bitrate} kbps</span>}
                                                {station.hls === 1 && <span className="mx-1 inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">HLS</span>}
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0 pr-2">
                                            <button type="button" onClick={() => createStation(station)} className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                                <span className="sr-only">Open options</span>
                                                <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>}
                </div>
            </div>}
        </div>
    )
}