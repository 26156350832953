import React, { useState } from 'react';
import { Link } from "react-router-dom";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export default function StationListFragment({ stations, regionId, sectionId }) {
    const [currentAddress, setCurrentAddress] = useState(undefined);

    const imageFromCKRecord = (artworkValue) => {
        return "data:image/png;base64," + artworkValue;
    };

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            {!stations && <p>Loading stations</p>}

            {stations && (stations.length === 0 || Object.keys(stations).length === 0) && <p>No stations found.</p>}

            {stations && stations.length > 0 && currentAddress && <>
                <AudioPlayer src={currentAddress} onPlay={e => console.log("onPlay")} showJumpControls={false} layout='horizontal' />
            </>}

            {stations && stations.length > 0 && <ul className="divide-y divide-gray-200">
                {stations.map((item, itemIndex) => (
                    /* guid is item.recordName */
                    <li key={itemIndex}>

                        <div className="flex items-center px-4 py-4 sm:px-6">
                            <div className="min-w-0 flex-1 flex items-center">
                                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-5 md:gap-4">
                                    <div>
                                        <p className="text-sm font-medium text-black-600 truncate">
                                            {item.fields.artwork && <img src={imageFromCKRecord(item.fields.artwork.value)} className="w-14 h-14" alt="logo" />}
                                        </p>
                                    </div>
                                    <div>
                                        {item.fields.widget &&
                                            <p className="text-sm font-medium text-black-600 truncate bg-gray-500 w-14 h-14">
                                                <img src={imageFromCKRecord(item.fields.widget.value)} className="w-14 h-14" alt="logo" />
                                            </p>}
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-black-600 truncate">{item.fields.title.value}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-black-600 truncate">
                                            {item.fields.iso3166.value.map((iso, index) => (
                                                <span key={index}>{(index ? ', ' : '') + iso}</span>
                                            ))}
                                            &nbsp; - {item.fields.iso639.value}
                                        </p>
                                    </div>

                                    <div>
                                        <p className="text-sm font-medium text-black-600 truncate flex space-x-3">
                                            <button type="button" className="disabled:opacity-50 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:w-auto sm:text-sm" onClick={() => setCurrentAddress(item.fields.address.value)}>
                                                Play
                                            </button>

                                            <Link to={`/app/featured/${regionId}/${sectionId}/${item.recordName}`} className="hover:bg-gray-50">
                                                <button type="button" className="disabled:opacity-50 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-isober-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-isober-500 sm:w-auto sm:text-sm">
                                                    Details
                                                </button>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            }
        </div>
    )
}