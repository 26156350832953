import React from 'react';
import { useSupportedRegions } from './Hooks';
import { Link } from "react-router-dom";

export default function RegionList({ environment }) {
    // HOOKS
    const regions = useSupportedRegions();

    return (
        <div>
            <h2 className="text-gray-500 text-sm font-medium">Supported regions in {environment.path}</h2>
            <ul className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                {regions.map((region) => (
                    <Link key={region.code} to={`/app/featured/${region.code}`}>
                    <li key={region.code} className="col-span-1 flex shadow-sm rounded-md">
                        <div className={'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'} >
                            <img src={`/flags/${region.flag}`} alt="" />
                        </div>
                        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                                {region.name}
                                {/*<p className="text-gray-500">{project.members} Members</p>*/}
                            </div>
                        </div>
                    </li>
                    </Link>
                ))}
            </ul>
        </div>
    )
}