import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LandingPage from './Landing/Index';
import PortalPage from './Portal/Index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoadingProgress from "./LoadingProgress.js";
import { FirebaseAppProvider } from 'reactfire';

const fbconfig = {
    apiKey: "AIzaSyCWHjbU0hnMYH0nDQBj5flgdUrIq_B-Pl8",
    authDomain: "transmission-vs.firebaseapp.com",
    projectId: "transmission-vs",
    storageBucket: "transmission-vs.appspot.com",
    messagingSenderId: "487699116186",
    appId: "1:487699116186:web:fd3b54116da882fa842f23"
  };

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<LoadingProgress />}>
            <FirebaseAppProvider firebaseConfig={fbconfig} suspense={true}>
                <Router>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/app/*" element={<PortalPage />} />
                    </Routes>
                </Router>
            </FirebaseAppProvider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);