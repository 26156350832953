import React, {  } from 'react';
import { Routes, Route } from 'react-router-dom';
import RegionList from './RegionList';
import RegionDetail from './RegionDetail';
import SectionDetail from './SectionDetail';
import CreateStation from './CreateStation';
import StationDetail from './StationDetail';

export default function FeaturedMain({ environment }) {

    return (
        <div>
            <Routes> {/* relative to /app/ */}
                <Route path="*" element={<RegionList environment={environment} />} />
                <Route path=":regionId" element={<RegionDetail environment={environment} />} />
                <Route path=":regionId/:sectionId" element={<SectionDetail environment={environment} />} />
                <Route path=":regionId/:sectionId/:stationId" element={<StationDetail environment={environment} />} />
                <Route path=":regionId/:sectionId/create" element={<CreateStation environment={environment} />} />
            </Routes>
        </div>
    )
}