import axios from 'axios';
import * as Yup from "yup"
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import ConfirmDialog from './ConfirmDialog';
import { Formik, ErrorMessage } from 'formik';
import React, { useState, useRef } from 'react';

export default function EditSectionFragment({ handler, section, environment }) {
    // STATE
    const [isSubmitting, setisSubmitting] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    // HOOKS
    const formikRef = useRef(null);
    const auth = useAuth();

    // HELPERS

    const implodeIso3166 = (arr) => {
        return arr.join(',');
    }

    const explodeIso3166 = (str) => {
        return str.split(',');
    }

    // FORM

    const initialValues = {
        name: section.fields.name.value,
        iso3166: (section.fields.iso3166.value !== undefined) ? implodeIso3166(section.fields.iso3166.value) : '',
        iso639: (section.fields.iso639 !== undefined) ? section.fields.iso639.value.toUpperCase() : '',
        active: (section.fields.active !== undefined) ? section.fields.active.value : '',
        position: (section.fields.position !== undefined) ? section.fields.position.value : '',
    };
    const validationSchema = Yup.object({
        name: Yup.string().required(),
        iso3166: Yup.string().required(),
        iso639: Yup.string().test('len', 'Must be 2 characters', val => (val !== undefined && val.trim().length === 2)),
        active: Yup.number().min(0).max(1),
        position: Yup.number().min(0).max(999),
    });

    const getChangedValues = (values, initialValues) => {
        return Object
          .entries(values)
          .reduce((acc, [key, value]) => {
            const hasChanged = initialValues[key] !== value
      
            if (hasChanged) {
              acc[key] = value
            }
      
            return acc
          }, {})
      }

    async function handleSubmit(form) {
        setisSubmitting(true);

        // start with required fields
        const updatePayload = {
            "environment": environment.path,
            "recordName": section.recordName,
            "fields": {  }
        };

        // detect which fields changed
        const changedFields = getChangedValues(formikRef.current.values, formikRef.current.initialValues);

        // merge into the form, with some special cases
        Object.entries(changedFields).forEach(([key, value]) => {
            if (key === "active" || key === "position") {
                updatePayload.fields[key] = { "value": Number(value) }
            } else if (key === "iso3166") {
                updatePayload.fields[key] = { "value": explodeIso3166(value) }
            } else {
                updatePayload.fields[key] = { "value": value }
            }
        });

        // Debug:
        // window.alert(JSON.stringify(updatePayload));  return;

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                axios.post("https://transmissionfm.app/api/featured/updatesection", updatePayload)
                    .then(function (response) {
                        if (response.data !== undefined && response.data.error !== undefined && response.data.error === false) {
                            setConfirmDialogOpen(true);
                            setisSubmitting(false);
                        } else {
                            window.alert("Invalid data received:" + JSON.stringify(response.data.error));
                            setisSubmitting(false);
                        }
                    })
                    .catch(function (error) {
                        window.alert(error);
                        setisSubmitting(false);
                    });
            })
            .catch((errorJwt) => {
                window.alert("Error - invalid auth token");
                setisSubmitting(false);
            });
    }

    // LIFTING STATE

    const confirmDialogHandler = (isOpen) => {
        setConfirmDialogOpen(isOpen);
        handler(true);
    }

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

            <ConfirmDialog defaultOpen={confirmDialogOpen} handler={confirmDialogHandler} message="The section was updated successfully." />

            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, errors }) => (
                    <form onSubmit={handleSubmit} className="">

                        {/* Debug */}
                        {Object.entries(errors).map(([key, value]) => {
                            return <p className='mb-2 text-red-900'><span className='font-bold'>{key}:</span> {value}</p>;
                        })}

                        <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Name &nbsp;
                                        <ErrorMessage name="name" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="name" id="name" defaultValue={initialValues.name} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        ISO 3166 &nbsp;
                                        <ErrorMessage name="iso3166" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="iso3166" id="iso3166" defaultValue={initialValues.iso3166} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        ISO 639 &nbsp;
                                        <ErrorMessage name="iso639" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="iso639" id="iso639" defaultValue={initialValues.iso639} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Enabled &nbsp;
                                        <ErrorMessage name="active" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="active" id="active" defaultValue={initialValues.active} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Position &nbsp;
                                        <ErrorMessage name="position" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="position" id="position" defaultValue={initialValues.position} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        {/* Button actions */}
                        <div className="mt-5 flex justify-end"> {/* disabled={formLoading || !isValid}  */}
                            <button type="submit" disabled={isSubmitting || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-blue-500 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900">
                                Save
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div >
    )
}
