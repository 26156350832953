import React, { useState } from 'react';
import { HomeIcon } from '@heroicons/react/solid'
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSupportedRegions, useCloudKitSection, useCloudKitStation } from './Hooks';
import EditStationFragment from './EditStationFragment';
import StationDetailFragment from './StationDetailFragment';
import EditArtworkFragment from './EditArtworkFragment';
import axios from 'axios';
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export default function StationDetail({ environment }) {
    // STATE
    const [editMode, setEditMode] = useState(false);
    const [editArtworkMode, setEditArtworkMode] = useState(false);
    const [isSubmitting, setisSubmitting] = useState(false);

    // HOOKS
    const { regionId } = useParams();
    const { sectionId } = useParams();
    const { stationId } = useParams();
    const regions = useSupportedRegions();
    const section = useCloudKitSection(sectionId, environment);
    const station = useCloudKitStation(stationId, environment);
    const region = regions.find(item => item.code === regionId);
    const navigate = useNavigate();
    const auth = useAuth();

    const toggleEdit = () => {
        setEditMode(!editMode);
    }

    const toggleEditArtwork = () => {
        setEditArtworkMode(!editArtworkMode);
    }

    const deleteStation = () => {
        if (!station || !station.recordName || station.recordName.length !== 36) {
            window.alert("Strange, station ID is invalid.");
            return;
        }

        if (window.confirm("Are you sure?")) {
            const postPayload = { 
                "environment": environment.path,
                "id": station.recordName 
            };
            getIdToken(auth.currentUser, false)
                .then(jwtToken => {
                    axios.post("https://transmissionfm.app/api/featured/deletestation", postPayload)
                        .then(function (response) {
                            if (response && response.data) {
                                navigate(`/app/featured/${regionId}/${sectionId}`);
                            } else {
                                window.alert("Invalid data received");
                            }
                        })
                        .catch(function (error) {
                            window.alert(error);
                        });
                })
                .catch((errorJwt) => {
                    window.alert("Error - invalid auth token");
                });
        }
    }

    const cloneToDev = () => {
        if (window.confirm("Are you sure?")) {
            setisSubmitting(true);

            getIdToken(auth.currentUser, false)
                .then(jwtToken => {
                    axios.post("https://transmissionfm.app/api/featured/clone", { "stationId": stationId })
                        .then(function (response) {
                            setisSubmitting(false);
                            window.alert(response.data.message);
                        })
                        .catch(function (error) {
                            setisSubmitting(false);
                            window.alert(error);
                        });
                })
                .catch((errorJwt) => {
                    setisSubmitting(false);
                    window.alert("Error - invalid auth token");
                });
        }
    }

    if (!station) {
        return <p>Loading station</p>;
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-2">

                        {/* Breadcrumbs */}
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
                                <li className="flex">
                                    <div className="flex items-center">
                                        <Link to="/app/featured" className="text-gray-600 hover:text-black">
                                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                        </Link>
                                    </div>
                                </li>
                                <li key="00" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <Link to={`/app/featured/${regionId}`} className="ml-4 text-sm font-medium text-gray-600 hover:text-black">
                                            {region.name}
                                        </Link>
                                    </div>
                                </li>
                                <li key="01" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <Link to={`/app/featured/${regionId}/${sectionId}`} className="ml-4 text-sm font-medium text-gray-600 hover:text-black">
                                            {section && section.fields && section.fields.name.value}
                                        </Link>
                                    </div>
                                </li>
                                <li key="02" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <span className="ml-4 text-sm font-medium text-black">{station.fields.title.value}</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                        {(!editMode && environment.path === "production") && <button type="button" onClick={() => cloneToDev() } disabled={isSubmitting} className="disabled:opacity-50 mx-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Clone to dev</button>}
                        {editMode && <button type="button" onClick={() => deleteStation() } disabled={isSubmitting} className="disabled:opacity-50 mx-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Delete station</button>}
                        {!editMode && <button type="button" onClick={() => toggleEditArtwork() } disabled={isSubmitting} className="disabled:opacity-50 mx-2 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {editArtworkMode && "Cancel"}
                            {!editArtworkMode && "Edit artwork"}
                        </button>}
                        {!editArtworkMode && <button type="button" onClick={() => toggleEdit() } disabled={isSubmitting} className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {editMode && "Cancel"}
                            {!editMode && "Edit station"}
                        </button>}
                    </div>
                </div>

            </div>

            {station.fields.address && <div>
                <AudioPlayer src={station.fields.address.value} onPlay={e => console.log("onPlay")} showJumpControls={false} layout='horizontal' />
            </div> }

            { !editArtworkMode && station !== undefined && !editMode && <StationDetailFragment station={station} environment={environment} /> }
            { !editArtworkMode && station !== undefined && editMode && <EditStationFragment regionId={regionId} sectionId={sectionId} station={station} environment={environment} /> }
            { editArtworkMode && <EditArtworkFragment stationId={stationId} environment={environment} /> }
        </div>
    )
}