import axios from 'axios';
import * as Yup from "yup"
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import ConfirmDialog from './ConfirmDialog';
import { Formik, ErrorMessage } from 'formik';
import { useNavigate } from "react-router-dom";
import React, { useState, useRef } from 'react';

export default function CreateStationFragment({ region, section, station, environment }) {
    // STATE
    const [isSubmitting, setisSubmitting] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    // HOOKS
    const navigate = useNavigate();
    const formikRef = useRef(null);
    const auth = useAuth();

    // FORM

    const initialValues = {
        apiProvider: 1,
        title: station.name,
        address: station.url,
        tags: (station.tags !== undefined && station.tags !== "undefined") ? station.tags : '', // FIXME: undefined is coming through as a string!
        iso3166: region.code,
        iso639: station.languagecodes.toUpperCase(),
        state: station.state,
        countryCode: (station.countrycode !== undefined && station.countrycode !== "undefined") ? station.countrycode : region.code,
        section: section.recordName,
        hasExtendedInfo: (station.has_extended_info && station.has_extended_info === true) ? 1 : 0,
        stationguid: station.stationuuid,
        changeguid: station.changeuuid,
        subtitle: undefined,
        description: undefined,
        instagram: undefined,
        youtube: undefined,
        bitrate: station.bitrate,
        codec: station.codec,
        supportsHls: (station.hls && station.hls === true) ? 1 : 0,
        samplingFrequency: station.samplingFrequency,
        countrySubCode: station.countrysubcode,
        homepage: station.homepage,
        languageCodes: station.languagecodes,
        twitter: undefined,
    };
    const validationSchema = Yup.object({
        stationguid: Yup.string().required(),
        changeguid: Yup.string().required(),
        title: Yup.string().required(),
        subtitle: Yup.string().optional(),
        state: Yup.string().optional(),
        countryCode: Yup.string().optional(),
        description: Yup.string().optional(),
        tags: Yup.string().optional(),
        address: Yup.string().url().required(),
        instagram: Yup.string().optional(),
        youtube: Yup.string().optional(),
        iso639: Yup.string().test('len', 'Must be 2 characters', val => (val !== undefined && val.trim().length === 2)),
        bitrate: Yup.number().optional(),
        codec: Yup.string().optional(),
        supportsHls: Yup.number().optional(),
        samplingFrequency: Yup.number().optional(),
        countrySubCode: Yup.string().optional(),
        hasExtendedInfo: Yup.number().optional(),
        homepage: Yup.string().optional(),
        languageCodes: Yup.string().optional(),
        twitter: Yup.string().optional(),
    });

    async function handleSubmit(form) {
        setisSubmitting(true);

        // Debug:
        // window.alert(JSON.stringify(form)); return;

        // start with required fields: title, section, address, iso3166, iso639 and default active, apiProvider
        const creationPayload = {
            "environment": environment.path,
            "fields": {
                "iso3166": { "value": [form.iso3166] },
                "title": { "value": form.title },
                "section": { "value": form.section },
                "address": { "value": form.address },
                "iso639": { "value": form.iso639 },
                "active": { "value": 1 },
                "apiProvider": { "value": form.apiProvider },
                "guid": { "value": form.stationguid },
                "changeGuid": { "value": form.changeguid },
            }
        };

        // merge in any optional fields
        if (form.countryCode) { creationPayload.fields.countryCode = { "value": form.countryCode}; }
        if (form.description) { creationPayload.fields.descr = { "value": form.description}; }
        if (form.instagram) { creationPayload.fields.instagram = { "value": form.instagram}; }
        if (form.state) { creationPayload.fields.state = { "value": form.state}; }
        if (form.subtitle) { creationPayload.fields.subtitle = { "value": form.subtitle}; }
        if (form.tags) { creationPayload.fields.tags = { "value": form.tags}; }
        if (form.bitrate) { creationPayload.fields.bitrate = { "value": Number(form.bitrate)}; }
        if (form.codec) { creationPayload.fields.codec = { "value": form.codec}; }
        if (form.supportsHls !== undefined) { creationPayload.fields.supportsHls = { "value": Number(form.supportsHls)}; }
        if (form.samplingFrequency) { creationPayload.fields.samplingFrequency = { "value": Number(form.samplingFrequency)}; }
        if (form.countrySubCode) { creationPayload.fields.countrySubCode = { "value": form.countrySubCode}; }
        if (form.hasExtendedInfo !== undefined) { creationPayload.fields.hasExtendedInfo = { "value": Number(form.hasExtendedInfo)}; }
        if (form.homepage) { creationPayload.fields.homepage = { "value": form.homepage}; }
        if (form.languageCodes) { creationPayload.fields.languageCodes = { "value": form.languageCodes}; }
        if (form.twitter) { creationPayload.fields.twitter = { "value": form.twitter}; }
        if (form.youtube) { creationPayload.fields.youtube = { "value": form.youtube}; }

        // Debug:
        // window.alert(JSON.stringify(creationPayload)); return;

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                axios.post("https://transmissionfm.app/api/featured/createstation", creationPayload)
                    .then(function (response) {
                        if (response.data !== undefined && response.data.error !== undefined && response.data.error === false) {
                            setConfirmDialogOpen(true);
                            setisSubmitting(false);
                        } else {
                            window.alert("Invalid data received:" + JSON.stringify(response.data.error));
                            setisSubmitting(false);
                        }
                    })
                    .catch(function (error) {
                        window.alert(error);
                        setisSubmitting(false);
                    });
            })
            .catch((errorJwt) => {
                window.alert("Error - invalid auth token");
                setisSubmitting(false);
            });
    }

    // LIFTING STATE

    const confirmDialogHandler = (isOpen) => {
        setConfirmDialogOpen(isOpen);
        navigate(`/app/featured/${region.code}/${section.recordName}`);
    }

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

            <ConfirmDialog defaultOpen={confirmDialogOpen} handler={confirmDialogHandler} message="The station was created successfully." />

            <Formik innerRef={formikRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
                {({ handleSubmit, handleChange, handleBlur, isValid, dirty, errors }) => (
                    <form onSubmit={handleSubmit} className="">

                        {/* Debug */}
                        {Object.entries(errors).map(([key, value]) => {
                            return <p key={key} className='mb-2'><span className='font-bold'>{key}:</span> {value}</p>;
                        })}

                        <div>
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Title &nbsp;
                                        <ErrorMessage name="title" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="title" id="title" defaultValue={initialValues.title} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Subtitle &nbsp;
                                        <ErrorMessage name="subtitle" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="subtitle" id="subtitle" defaultValue={initialValues.subtitle} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Description &nbsp;
                                        <ErrorMessage name="description" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="description" id="description" defaultValue={initialValues.description} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Address &nbsp;
                                        <ErrorMessage name="address" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="address" id="address" defaultValue={initialValues.address} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Tags &nbsp;
                                        <ErrorMessage name="tags" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="tags" id="tags" defaultValue={initialValues.tags} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">ISO 3166</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="iso3166" id="iso3166" defaultValue={initialValues.iso3166} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">Country sub code</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="countrySubCode" id="countrySubCode" defaultValue={initialValues.countrySubCode} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        ISO 639 &nbsp;
                                        <ErrorMessage name="iso639" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="iso639" id="iso639" defaultValue={initialValues.iso639} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Language codes (app display) &nbsp;
                                        <ErrorMessage name="languageCodes" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="languageCodes" id="languageCodes" defaultValue={initialValues.languageCodes} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        Homepage
                                        {initialValues.homepage && <span>&nbsp;<a className='text-blue-800' href={initialValues.homepage} window="_new">Open</a></span>}
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="homepage" id="homepage" defaultValue={initialValues.homepage} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">Twitter</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="twitter" id="twitter" defaultValue={initialValues.twitter} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">Instagram</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="instagram" id="instagram" defaultValue={initialValues.instagram} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">Youtube</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="youtube" id="youtube" defaultValue={initialValues.youtube} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">Country code</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="countryCode" id="countryCode" defaultValue={initialValues.countryCode} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-900">
                                        State &nbsp;
                                        <ErrorMessage name="state" component="span" className="text-red-700 italic" />
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="state" id="state" defaultValue={initialValues.state} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Codec</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="codec" id="codec" defaultValue={initialValues.codec} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Bitrate</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="bitrate" id="bitrate" defaultValue={initialValues.bitrate} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Sampling frequency</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="samplingFrequency" id="samplingFrequency" defaultValue={initialValues.samplingFrequency} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Supports HLS</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="supportsHls" id="supportsHls" defaultValue={initialValues.supportsHls} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Has extended info</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="hasExtendedInfo" id="hasExtendedInfo" defaultValue={initialValues.hasExtendedInfo} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">GUID</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="stationguid" id="stationguid" defaultValue={initialValues.stationguid} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Change GUID</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="changeguid" id="changeguid" defaultValue={initialValues.changeguid} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">API provider</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="provider" id="provider" defaultValue={initialValues.apiProvider} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Section</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <input type="text" name="section" id="section" defaultValue={initialValues.section} disabled onChange={handleChange} onBlur={handleBlur} autoComplete="off" className="p-1 block w-full border border-gray-300 pl-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        {/* Button actions */}
                        <div className="mt-5 flex justify-end"> {/* disabled={formLoading || !isValid}  */}
                            <button type="submit" disabled={isSubmitting || !dirty || !isValid} className="disabled:opacity-50 disabled:bg-blue-500 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900">
                                Save
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div >
    )
}

/* CloudKit schema:
active
address
apiProvider
artwork
bitrate
changeGuid
codec
countryCode
countrySubCode
descr
guid
hasExtendedInfo
homepage
instagram
iso3166
iso639
languageCodes
samplingFrequency
section
state
subtitle
supportsHls
tags
title
twitter
youtube
*/

/* props.region
    {"flag":"us.svg","name":"United States of America","code":"US"}
*/

/* props.section
{
    "recordName": "3D42AA0A-4D54-7E49-E169-0EE8A3F07BA3",
    "recordType": "FeaturedSection",
    "fields": {
        "iso639": {
            "value": "EN",
            "type": "STRING"
        },
        "name": {
            "value": "Talk Radio",
            "type": "STRING"
        },
        "position": {
            "value": 1,
            "type": "INT64"
        },
        "iso3166": {
            "value": [
                "GB",
                "ZA",
                "US",
                "AU",
                "IE"
            ],
            "type": "STRING_LIST"
        }
    },
    "pluginFields": {},
    "recordChangeTag": "l6t4rzx7",
    "created": {
        "timestamp": 1660469683888,
        "userRecordName": "_023fb7b130e43cc0e7f4d028161a567a",
        "deviceID": "2"
    },
    "modified": {
        "timestamp": 1660469683888,
        "userRecordName": "_023fb7b130e43cc0e7f4d028161a567a",
        "deviceID": "2"
    },
    "deleted": false
}
*/

/* props.station:
{
    "changeuuid": "974c0e53-770b-44a8-b200-446e9233ba95",
    "stationuuid": "445c73fe-8e05-4580-bece-68e76ad0add6",
    "serveruuid": "539049e5-6dab-422f-a825-f7d09c60a2e5",
    "name": "Radio Mitre",
    "url": "https://24483.live.streamtheworld.com/AM790_56.mp3",
    "url_resolved": "https://24483.live.streamtheworld.com/AM790_56.mp3",
    "homepage": "https://radiomitre.cienradios.com/",
    "favicon": "https://cloudfront-arc.cienradios.com/radiomitre/logo/logo-widget.svg",
    "tags": "politics,talk",
    "country": "Argentina",
    "countrycode": "AR",
    "iso_3166_2": null,
    "state": "",
    "language": "español argentina,spanish",
    "languagecodes": "es",
    "votes": 61,
    "lastchangetime": "2022-08-09 10:16:47",
    "lastchangetime_iso8601": "2022-08-09T10:16:47Z",
    "codec": "MP3",
    "bitrate": 96,
    "hls": 0,
    "lastcheckok": 1,
    "lastchecktime": "2022-09-03 10:29:18",
    "lastchecktime_iso8601": "2022-09-03T10:29:18Z",
    "lastcheckoktime": "2022-09-03 10:29:18",
    "lastcheckoktime_iso8601": "2022-09-03T10:29:18Z",
    "lastlocalchecktime": "2022-09-03 10:27:53",
    "lastlocalchecktime_iso8601": "2022-09-03T10:27:53Z",
    "clicktimestamp": "2022-09-03 03:48:10",
    "clicktimestamp_iso8601": "2022-09-03T03:48:10Z",
    "clickcount": 140,
    "clicktrend": -8,
    "ssl_error": 0,
    "geo_lat": -34.59586337110662,
    "geo_long": -58.40517002043691,
    "has_extended_info": false
}
*/