import React, { useState, useRef } from 'react';
import axios from 'axios';
import ConfirmDialog from './ConfirmDialog';
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";

export default function EditArtworkFragment({ stationId, environment }) {
    const auth = useAuth();
    const artworkImgRef = useRef()
    const widgetImgRef = useRef()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [artworkImgEncoded, setArtworkImgEncoded] = useState(undefined);
    const [widgetImgEncoded, setWidgetImgEncoded] = useState(undefined);

    const formatNumber = (n) => {
        let formatter = new Intl.NumberFormat('en-US');
        return formatter.format(n);
    }

    const handleArtworkImageUpload = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            artworkImgRef.current.file = file;
            reader.onload = (e) => {
                artworkImgRef.current.src = e.target.result;
                const deprefixed = e.target.result.replace(/^(data:image\/png;base64,)/, ''); // data:image/png;base64,
                setArtworkImgEncoded(deprefixed);
            }
            reader.readAsDataURL(file);
        }
    };

    const handleWidgetImageUpload = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            widgetImgRef.current.file = file;
            reader.onload = (e) => {
                widgetImgRef.current.src = e.target.result;
                const deprefixed = e.target.result.replace(/^(data:image\/png;base64,)/, ''); // data:image/png;base64,
                setWidgetImgEncoded(deprefixed);
            }
            reader.readAsDataURL(file);
        }
    };

    const confirmDialogHandler = (isOpen) => {
        setConfirmDialogOpen(isOpen);
        // FIXME handler
    }

    const submitArtwork = () => {
        if (stationId === undefined) {
            window.alert("Error: station ID is undefined");
            return;
        }

        setIsSubmitting(true);

        const updatePayload = {
            "environment": environment.path,
            "recordName": stationId,
            "artwork": artworkImgEncoded,
        };

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                axios.post("https://transmissionfm.app/api/featured/updateartwork", updatePayload)
                    .then(function (response) {
                        if (response.data !== undefined && response.data.error !== undefined && response.data.error === false) {
                            setConfirmDialogOpen(true);
                            setIsSubmitting(false);
                        } else {
                            window.alert("Invalid data received:" + JSON.stringify(response.data.message));
                            setIsSubmitting(false);
                        }
                    })
                    .catch(function (error) {
                        window.alert(error);
                        setIsSubmitting(false);
                    });
            })
            .catch((errorJwt) => {
                window.alert("Error - invalid auth token");
                setIsSubmitting(false);
            });
    }

    const submitWidget = () => {
        if (stationId === undefined) {
            window.alert("Error: station ID is undefined");
            return;
        }

        setIsSubmitting(true);

        const updatePayload = {
            "environment": environment.path,
            "recordName": stationId,
            "widget": widgetImgEncoded,
        };

        getIdToken(auth.currentUser, false)
            .then(jwtToken => {
                axios.post("https://transmissionfm.app/api/featured/updatewidget", updatePayload)
                    .then(function (response) {
                        if (response.data !== undefined && response.data.error !== undefined && response.data.error === false) {
                            setConfirmDialogOpen(true);
                            setIsSubmitting(false);
                        } else {
                            window.alert("Invalid data received:" + JSON.stringify(response.data.message));
                            setIsSubmitting(false);
                        }
                    })
                    .catch(function (error) {
                        window.alert(error);
                        setIsSubmitting(false);
                    });
            })
            .catch((errorJwt) => {
                window.alert("Error - invalid auth token");
                setIsSubmitting(false);
            });
    }

    return (
        <div className="px-4 py-4 bg-white shadow overflow-hidden sm:rounded-lg">
            <ConfirmDialog defaultOpen={confirmDialogOpen} handler={confirmDialogHandler} message="The artwork was updated successfully." />

            <div className="grid grid-cols-2 gap-4">
                <div> {/* left col */}
                    <input type="file" accept="image/png" multiple={false} disabled={isSubmitting} onChange={handleArtworkImageUpload} />
                    <div className='flex'>
                        {/* Image placeholder */}
                        <div className='my-4 w-80 h-80 ' >
                            <img ref={artworkImgRef} className='w-80 h-80 border-none border-0' alt="" />
                        </div>

                        {/* Debug area */}
                        <div className='my-4 mx-4 grow flex flex-col justify-end'>
                            <div className='flex flex-row justify-end align-center items-center'>
                                {artworkImgEncoded && <span>{formatNumber(artworkImgEncoded.length) + " bytes"}</span>}
                                <button type="button" onClick={() => submitArtwork()} disabled={!artworkImgEncoded || isSubmitting} className="disabled:opacity-50 disabled:bg-blue-500 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div> {/* right col */}
                <input type="file" accept="image/png" multiple={false} disabled={isSubmitting} onChange={handleWidgetImageUpload} />
                    <div className='flex'>
                        {/* Image placeholder */}
                        <div className='my-4 w-20 h-20 bg-gray-500' >
                            <img ref={widgetImgRef} className='w-20 h-20 border-none border-0' alt="" />
                        </div>

                        {/* Debug area */}
                        <div className='my-4 mx-4 grow flex flex-col justify-end'>
                            <div className='flex flex-row justify-end align-center items-center'>
                                {widgetImgEncoded && <span>{formatNumber(widgetImgEncoded.length) + " bytes"}</span>}
                                <button type="button" onClick={() => submitWidget()} disabled={!widgetImgEncoded || isSubmitting} className="disabled:opacity-50 disabled:bg-blue-500 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-900 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}