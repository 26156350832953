import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from 'reactfire';
import { getIdToken } from "firebase/auth";
import { HomeIcon } from '@heroicons/react/solid'
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSupportedRegions, useCloudKitSection, useCloudKitStations } from './Hooks';
import StationListFragment from './StationListFragment';
import EditSectionFragment from './EditSectionFragment';

export default function SectionDetail({ environment }) {
    // STATE
    const [editMode, setEditMode] = useState(false);

    // HELPERS

    const implodeIso3166 = (arr) => {
        return arr.join(',');
    }

    // HOOKS
    const { regionId } = useParams();
    const { sectionId } = useParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const regions = useSupportedRegions();
    const section = useCloudKitSection(sectionId, environment);
    const stations = useCloudKitStations(regionId, sectionId, environment);
    const region = regions.find(item => item.code === regionId);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const deleteSection = () => {
        if (!section || !section.recordName || section.recordName.length !== 36) {
            window.alert("Strange, section ID is invalid.");
            return;
        }

        if (window.confirm("Are you sure?")) {
            const postPayload = { 
                "environment": environment.path,
                "id": section.recordName 
            };
            getIdToken(auth.currentUser, false)
                .then(jwtToken => {
                    axios.post("https://transmissionfm.app/api/featured/deletesection", postPayload)
                        .then(function (response) {
                            if (response && response.data) {
                                navigate(`/app/featured/${regionId}`);
                            } else {
                                window.alert("Invalid data received");
                            }
                        })
                        .catch(function (error) {
                            window.alert(error);
                        });
                })
                .catch((errorJwt) => {
                    window.alert("Error - invalid auth token");
                });
        }
    }

    const showCreate = () => {
        navigate(`/app/featured/${regionId}/${sectionId}/create`);
    }

    // LIFTING STATE

    const updateSectionHandler = (isDone) => {
        setEditMode(false);
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-2">

                        {/* Breadcrumbs */}
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="flex space-x-4 rounded-md bg-white px-6 shadow">
                                <li className="flex">
                                    <div className="flex items-center">
                                        <Link to="/app/featured" className="text-gray-600 hover:text-black">
                                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                        </Link>
                                    </div>
                                </li>
                                <li key="00" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <Link to={`/app/featured/${regionId}`} className="ml-4 text-sm font-medium text-gray-600 hover:text-black">
                                            {region.name}
                                        </Link>
                                    </div>
                                </li>
                                <li key="01" className="flex">
                                    <div className="flex items-center">
                                        <svg className="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                                        </svg>
                                        <span className="ml-4 text-sm font-medium text-black">
                                            {section && section.fields && section.fields.name.value}
                                            {(!section || !section.fields) && "Section"}
                                        </span>
                                    </div>
                                </li>
                            </ol>
                        </nav>

                    </div>
                    <div className="ml-4 mt-2 flex-shrink-0">
                        {editMode && <button type="button" onClick={() => { deleteSection() }} className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Delete section</button>}
                        {!editMode && <button type="button" onClick={() => { showCreate() }} className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create station</button>}
                        <button type="button" onClick={() => { toggleEditMode() }} className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {!editMode && "Edit section"}
                            {editMode && "Cancel"}
                        </button>
                    </div>
                </div>
            </div>

            {/* section details row */}
            { section && !editMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-5">
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-900">Section name</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {section.fields.name.value && section.fields.name.value}
                            {!section.fields.name.value && "—"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-900">ISO 3166</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {section.fields.iso3166.value && implodeIso3166(section.fields.iso3166.value)}
                            {!section.fields.iso3166.value && "—"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-900">ISO 639</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {section.fields.iso639.value && section.fields.iso639.value}
                            {!section.fields.iso639.value && "—"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-900">Position</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {section.fields.position.value && section.fields.position.value}
                            {!section.fields.position.value && "—"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-900">Enabled</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {section.fields.active && section.fields.active.value && section.fields.active.value }
                            {!section.fields.active && "—"}
                        </dd>
                    </div>
                </dl>
            </div>
            }

            {/* main area */}
            {!editMode && <StationListFragment stations={stations} regionId={regionId} sectionId={sectionId} />}
            {editMode && <EditSectionFragment section={section} handler={updateSectionHandler} environment={environment} />}
        </div>
    )
}